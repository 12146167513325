:root {
  --primary: #0061A7;
  --primary-color: #0061A7;
  --btn-primary: var(--primary-color) !important;
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/></svg>") !important;
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'></path></svg>") !important;
}

.Abtn-primary {
  background-color: var(--primary-color);
}

.Abtn-primary:active {
  background-color: var(--primary-color) !important;
}

.btn.btn-primary:hover {
  background-color: #005b9d !important;
}

.btn.btn-primary:active {
  background-color: #00528e !important;
}

.list-sidebar-button:active,
.form-control:focus {
  box-shadow: 0 0 0 2px rgba(0, 97, 167, 0.3);
}

.btn.btn-default:active {
  border-color: #005b9d !important;
}

.btn.btn-default:hover {
  border-color: #00528e !important;
}

.list-sidebar-button:active {
  box-shadow: 0 0 0 2px rgba(0, 97, 167, 0.3);
}

.list-sidebar-button:hover {
  box-shadow: 0 0 0 2px rgba(0, 97, 167, 0.5);
}

.navbar {
  background-color: #0061A7;
}

ul#navbar-breadcrumbs,
#navbar-breadcrumbs a,
#navbar-breadcrumbs a:before {
  color: white;
}

#navbar-breadcrumbs a:before {
  content: var(--right-arrow-svg);
}

#navbar-breadcrumbs li.disabled a {
  color: #b3b3b3;
}

.navbar {
  background-color: #0061A7;
}